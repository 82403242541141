import { defineStore } from "pinia";
import { ref } from "vue";
import coachApi from "@/services/api/coachApi";
import ICoach from "@/types/ICoach";
import { AxiosResponse } from "axios";

export const useCoachStore = defineStore(
  "coach",
  () => {
    const data = ref<Partial<ICoach>>({});
    const coachAccessToken = ref(localStorage.getItem("coachAccessToken"));
    const coachRefreshToken = ref(localStorage.getItem("coachRefreshToken"));
    const rememberMe = ref(false);

    function login(payload: {
      password: string;
      email: string;
    }): Promise<AxiosResponse> {
      return coachApi.logIn(payload).then((res) => {
        setTokens({
          accessToken: res.data.data.accessToken,
          refreshToken: res.data.data.refreshToken,
        });
        data.value = res.data.data.coach;
        return res;
      });
    }

    function setTokens(payload: {
      accessToken: string;
      refreshToken: string;
      rememberMe?: boolean;
    }): void {
      coachAccessToken.value = payload.accessToken;
      coachRefreshToken.value = payload.refreshToken;
      if (typeof payload.rememberMe === "boolean") {
        rememberMe.value = payload.rememberMe;
      }
      localStorage.setItem("coachAccessToken", payload.accessToken);
      if (rememberMe.value || localStorage.getItem("coachRefreshToken")) {
        localStorage.setItem("coachRefreshToken", payload.refreshToken);
      }
    }

    function logout(): Promise<AxiosResponse> {
      return coachApi.logOut().then((res) => {
        clear();
        return res;
      });
    }

    function clear() {
      data.value = {};
      coachAccessToken.value = "";
      coachRefreshToken.value = "";
      rememberMe.value = false;
      localStorage.removeItem("coachAccessToken");
      localStorage.removeItem("coachRefreshToken");
    }

    function getMe() {
      return coachApi.getMe().then((res) => {
        data.value = res.data.data;
        return res;
      });
    }

    function updateProfile(payload: { fullName: string }) {
      return coachApi.updateProfile(payload).then((res) => {
        getMe();
        return res;
      });
    }

    function updateEmail(payload: { newEmail: string; password: string }) {
      return coachApi.updateEmail(payload).then((res) => {
        getMe();
        return res;
      });
    }

    return {
      data,
      coachAccessToken,
      coachRefreshToken,
      rememberMe,
      login,
      logout,
      clear,
      setTokens,
      updateProfile,
      updateEmail,
      getMe,
    };
  },
  {
    persist: true,
  }
);
