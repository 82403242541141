import { io } from "socket.io-client";
import { App } from "vue";
import { useUserStore } from "@/store/user";
import { useCoachStore } from "@/store/coach";

export default {
  install: (app: App, { connection, options }: any) => {
    const userStore = useUserStore();
    const coachStore = useCoachStore();
    const socket = io(`${connection}/v1/notifications`, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${userStore.accessToken}`,
          },
        },
      },
    });

    const socketChat = io(`${connection}/v1/support/chats`, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${
              userStore.accessToken
                ? userStore.accessToken
                : coachStore.coachAccessToken
            }`,
          },
        },
      },
    });

    socket.on("exception", (error) => {
      console.log("error :>> ", error);
    });

    socketChat.on("exception", (error) => {
      console.log("error :>> ", error);
    });

    app.config.globalProperties.$socket = socket;
    app.provide("socket", socket);
    app.provide("socketChat", socketChat);
  },
};
