import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.layout))),
    ($setup.isOpenFailedStatusModal)
      ? (_openBlock(), _createBlock($setup["FailedStatusModal"], {
          key: 0,
          isOpen: $setup.isOpenFailedStatusModal,
          onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.isOpenFailedStatusModal = false)),
          onSubmit: _cache[1] || (_cache[1] = ($event: any) => ($setup.isOpenFailedStatusModal = false))
        }, null, 8, ["isOpen"]))
      : _createCommentVNode("", true)
  ], 64))
}