import axiosClient from "./index";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import IOnboardingStartCourse from "@/types/IOnboardingStartCourse";
import ICalculate from "@/types/ICalculate";

export default {
  getCourses(
    params = {} as {
      "filter[title]"?: string;
      "filter[type]": string;
      "page[limit]": number;
      "page[skip]": number;
    }
  ): Promise<AxiosResponse> {
    return axiosClient.get("courses", { params });
  },
  getCoursesRandom(
    params = {} as {
      "filter[limit]": number;
      "filter[challengeId]": string;
    }
  ): Promise<AxiosResponse> {
    return axiosClient.get(`courses/random`, { params });
  },
  getRecommendedCourses(
    params = {} as {
      "filter[date]": string;
    }
  ): Promise<AxiosResponse> {
    return axiosClient.post(`users/recommended`, { params });
  },
  getCourse(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`courses/${id}`);
  },
  getExercisesList(
    params = {} as {
      id: string;
      query: {
        month: number;
        trainingFrequency: string;
        day: number;
      };
    }
  ): Promise<AxiosResponse> {
    return axiosClient.get(`courses/${params.id}/exercises`, {
      params: params.query,
    });
  },
  getCurrentCourse(): Promise<AxiosResponse> {
    return axiosClient.get("users-courses");
  },
  cancelCourse(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`users-courses/${id}`);
  },
  startCourse(form: IOnboardingStartCourse): Promise<AxiosResponse> {
    return axiosClient.post("users-courses", form);
  },
  uploadFile(
    data: FormData,
    uploadProgressCallback?: (event: ProgressEvent) => void
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {};
    if (uploadProgressCallback) {
      config.onUploadProgress = (progressEvent: ProgressEvent) => {
        uploadProgressCallback(progressEvent);
      };
    }
    return axiosClient.post("upload", data, config);
  },
  getValuesChallenge(): Promise<AxiosResponse> {
    return axiosClient.get("users-courses/values-challenge");
  },
  getWorkoutChallenge(): Promise<AxiosResponse> {
    return axiosClient.get("users-courses/workout-challenge");
  },
  getCurrentCourseData(): Promise<AxiosResponse> {
    return axiosClient.get("users-courses/current");
  },
  updateCurrentCourseData(
    payload: Partial<ICalculate>
  ): Promise<AxiosResponse> {
    return axiosClient.patch("users-courses", payload);
  },
};
