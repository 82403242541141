import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "broadcastchannel-polyfill";
import { PiniaSharedState } from "pinia-shared-state";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Socketio from "@/services/socket";
import VueGtag from "vue-gtag";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const app = createApp(App);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://d202205b1dbd4963b4ca1dc315757ee3@sentry.onix.team/111",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "onix-systems-clubhouse-front.dev.onix.team",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: "production",
    logErrors: true,
  });
}

app
  .use(pinia)
  .use(router)
  .use(Socketio, {
    connection: `${process.env.VUE_APP_API_URL}`,
  })
  .use(VueGtag, {
    config: { id: `${process.env.VUE_APP_GA_PROPERTY_ID}` },
  })
  .use(VueGtag, {
    config: { id: `${process.env.VUE_APP_GA_PROPERTY_ID}` },
  })
  .mount("#app");
