import axios from "axios";
import router from "@/router";
import { useUserStore } from "@/store/user";
import { useCoachStore } from "@/store/coach";
import { computed } from "vue";
import { useSupportChat } from "@/composables/useSupportChat";

interface Subscriber {
  (token: string): void;
}

const baseURL = process.env.VUE_APP_API_URL
  ? `${process.env.VUE_APP_API_URL}/v1`
  : ``;
const axiosClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
let subscribers: Subscriber[] = [];
let isRefreshing = false;

function onRefreshed(accessToken: string): void {
  subscribers.forEach((cb) => cb(accessToken));
}

function subscribeTokenRefresh(cb: Subscriber): void {
  subscribers.push(cb);
}

axiosClient.interceptors.request.use(
  (request) => {
    const userStore = useUserStore();
    const coachStore = useCoachStore();
    const getAccessToken = computed(
      () => coachStore.coachAccessToken || userStore.accessToken
    );
    if (request.headers && getAccessToken.value) {
      request.headers.Authorization = `Bearer ${getAccessToken.value}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const userStore = useUserStore();
    const coachStore = useCoachStore();
    const { isCoach } = useSupportChat();
    const originalRequest = error.config;
    const rToken: string | null =
      coachStore.coachRefreshToken || userStore.refreshToken;
    const clearStoreFunc = (): void => {
      if (isCoach.value) {
        coachStore.clear();
        router.push("/support/login");
      } else {
        userStore.clear();
        router.push("/login");
      }
    };

    if (error.response && error.response.status === 401) {
      if (rToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          const url = isCoach.value
            ? "/coaches-auth/refresh-token"
            : "/auth/refresh-token";
          axios
            .post(baseURL + url, {
              refreshToken: rToken,
            })
            .then((response) => {
              const { accessToken, refreshToken } = response.data.data;
              (isCoach.value ? coachStore : userStore).setTokens({
                accessToken,
                refreshToken,
              });
              onRefreshed(accessToken);
              subscribers = [];
            })
            .catch(() => {
              clearStoreFunc();
            })
            .finally(() => {
              isRefreshing = false;
            });
        }
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosClient(originalRequest));
          });
        });
      } else {
        clearStoreFunc();
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
