import { computed, ref } from "vue";
import { useUserStore } from "@/store/user";
import dayjs from "dayjs";
import { ISubscription } from "@/types/IMe";

export function useManageSubscription(extraSubsList?: any) {
  const userStore = useUserStore();

  const currentMainSubs = computed<ISubscription | null | undefined>(
    () => userStore.data?.subscription
  );

  const isActiveMainSubs = computed<boolean>(
    () =>
      currentMainSubs.value?.status === "active" ||
      currentMainSubs.value?.status === "past_due" ||
      currentMainSubs.value?.status === "trialing"
  );

  const isPastDueMainSubs = computed<boolean>(
    () => currentMainSubs.value?.status === "past_due"
  );

  const currentMainPeriodLabel = computed<string>(() => {
    return (
      `${currentMainSubs.value?.details?.intervalCount} ` +
      `${currentMainSubs.value?.details?.interval[0]?.toUpperCase()}` +
      `${currentMainSubs.value?.details?.interval?.substring(1)}` +
      `${
        currentMainSubs.value?.details?.intervalCount &&
        currentMainSubs.value?.details?.intervalCount > 1
          ? "s"
          : ""
      }`
    );
  });
  // --------------------------------------------------------------------------------
  const currentExtraSubsList = computed<ISubscription[] | null | undefined>(
    () =>
      userStore.data?.extraSubscriptions?.filter(
        (item) => item.status !== "canceled"
      )
  );

  const currentExtraProductIdList = computed<string[] | null | undefined>(() =>
    currentExtraSubsList.value?.map((item) => item.productId)
  );

  const extraSubsListAvailable = computed<ISubscription[] | null | undefined>(
    () => {
      return extraSubsList.value?.filter(
        (item: ISubscription) =>
          !currentExtraProductIdList.value?.includes(item.productId)
      );
    }
  );

  const getExtraSubsBillingDesc = (priceId: string): string => {
    let result = "no data about additional service";
    const elem: ISubscription | undefined = currentExtraSubsList.value?.find(
      (item) => item.priceId === priceId
    );
    if (elem) {
      result =
        `${elem?.details?.name || ""}` +
        ` (${elem?.details?.intervalCount || ""} ` +
        `${elem?.details?.interval || ""}` +
        `${
          elem?.details?.intervalCount && elem?.details?.intervalCount > 1
            ? "s"
            : ""
        })`;
    }
    return result;
  };
  // --------------------------------------------------------------------------------

  const getMainSubsBillingDesc = computed<string>(() => {
    return (
      `${currentMainSubs.value?.details?.name || ""}` +
      ` (${
        currentMainSubs.value?.details?.intervalCount ||
        "no data about subscription"
      } ` +
      `${currentMainSubs.value?.details?.interval || ""}` +
      `${
        currentMainSubs.value?.details?.intervalCount &&
        currentMainSubs.value?.details?.intervalCount > 1
          ? "s"
          : ""
      })`
    );
  });

  const getMainSubsDesc = computed<string>(() => {
    let result = "";
    if (currentMainSubs.value?.cancelAtPeriodEnd) {
      result = `Your membership will be cancelled by ${dayjs(
        currentMainSubs.value?.canceledAt
      ).format("MMMM D, YYYY")}`;
    } else {
      result = `Your next payment will occur on ${dayjs(
        currentMainSubs.value?.end
      ).format("MMMM D, YYYY")}`;
    }
    return result;
  });

  return {
    currentMainSubs,
    getMainSubsBillingDesc,
    getMainSubsDesc,
    isActiveMainSubs,
    isPastDueMainSubs,
    currentMainPeriodLabel,
    currentExtraSubsList,
    getExtraSubsBillingDesc,
    extraSubsListAvailable,
  };
}
