import { defineStore } from "pinia";
import { computed, ref } from "vue";
import IChat from "@/types/IChat";
import IMessage from "@/types/IMessage";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useCoachStore } from "@/store/coach";
dayjs.extend(customParseFormat);

export const useChatStore = defineStore(
  "chat",
  () => {
    const coachStore = useCoachStore();
    const isCoach = computed(
      (): boolean =>
        !!(
          coachStore &&
          Object.keys(coachStore.data).length &&
          coachStore.coachAccessToken
        )
    );
    const initValue: IChat[] = [
      {
        chatName: "workout",
        coachIds: [],
        lastMessage: null,
        lastMessageAt: null,
        status: "closed",
        unreadCount: 0,
        userId: "",
        _id: "workout",
        isJoined: false,
        type: "workout",
      },
      {
        chatName: "nutrition",
        coachIds: [],
        lastMessage: null,
        lastMessageAt: null,
        status: "closed",
        unreadCount: 0,
        userId: "",
        _id: "nutrition",
        isJoined: false,
        type: "nutrition",
      },
      {
        chatName: "app",
        coachIds: [],
        lastMessage: null,
        lastMessageAt: null,
        status: "closed",
        unreadCount: 0,
        userId: "",
        _id: "app",
        isJoined: false,
        type: "app",
      },
    ];

    const data = ref<IChat[]>(isCoach.value ? [] : [...initValue]);

    const countUnreadMessage = computed((): number => {
      return data.value.reduce((acc, chat) => acc + chat.unreadCount, 0);
    });

    const isJoinFlag = ref(false);

    function setInitData() {
      data.value = [...initValue];
    }

    function clearData() {
      data.value = [];
    }

    function sortArrayFn(
      arr: IChat[],
      desc = true,
      sortByProp = "lastMessageAt"
    ): IChat[] {
      return [...arr]?.sort((a: IChat, b: IChat) =>
        !desc
          ? dayjs(a.lastMessageAt).unix() - dayjs(b.lastMessageAt).unix()
          : dayjs(b.lastMessageAt).unix() - dayjs(a.lastMessageAt).unix()
      );
    }

    function getChatIndexById(chatId: string): number {
      return data.value.findIndex((item) => item._id === chatId);
    }

    function deleteDataById(chatId: string): void {
      const idx = getChatIndexById(chatId);
      if (idx >= 0) {
        data.value.splice(idx, 1);
      }
    }

    function setData(payload: IChat[]): void {
      if (isCoach.value || payload.length) {
        data.value = payload;
      }
      data.value = sortArrayFn(data.value);
    }

    function joinData(payload: IChat[]): void {
      data.value = [...data.value, ...payload];
      data.value = sortArrayFn(data.value);
    }

    function updateDataById(payload: { data: IMessage; type: string }): void {
      const idx = getChatIndexById(payload.data.chatId);
      if (idx >= 0) {
        data.value[idx].lastMessage = payload.data.isDeleted
          ? "Deleted message"
          : payload.data.message || "new file";
        data.value[idx].lastMessageAt = payload.data.createdAt;

        if (
          payload.type === "inbox" &&
          ["common", "system_auto_reply"].includes(payload.data.type)
        ) {
          data.value[idx].unreadCount = data.value[idx].unreadCount + 1;
        }

        data.value = sortArrayFn(data.value);
      }
    }

    function decreaseMessage(chatId: string): void {
      const idx = getChatIndexById(chatId);
      if (idx >= 0 && data.value[idx].unreadCount > 0) {
        data.value[idx].unreadCount = data.value[idx].unreadCount - 1;
      }
    }

    return {
      setData,
      joinData,
      setInitData,
      clearData,
      updateDataById,
      decreaseMessage,
      deleteDataById,
      getChatIndexById,
      countUnreadMessage,
      data,
      isJoinFlag,
    };
  },
  {
    persist: false,
  }
);
