import axiosClient from "./index";
import { AxiosResponse } from "axios";
import IOnboarding from "@/types/IOnboarding";
import { Gender } from "@/enums/Gender";
import { Goal } from "@/enums/Goal";
import { MeasurementSystem } from "@/enums/MeasurementSystem";
import IDashboardSetting from "@/types/IDashboardSetting";

export default {
  logIn(payload: { password: string; email: string }): Promise<AxiosResponse> {
    return axiosClient.post("auth/sign-in", payload);
  },
  logOut(): Promise<AxiosResponse> {
    return axiosClient.delete("auth/logout");
  },
  signUp(payload: {
    password: string;
    passwordRepeat: string;
    email: string;
    promoCode?: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("/auth/sign-up", payload);
  },
  getMe(): Promise<AxiosResponse> {
    return axiosClient.get("users/me");
  },
  getAvailableSubscriptions(): Promise<AxiosResponse> {
    return axiosClient.get("users/subscriptions/available");
  },
  usePromoCode(payload: { code: string }): Promise<AxiosResponse> {
    return axiosClient.post("users/promo-code", payload);
  },
  userOnboarding(payload: IOnboarding): Promise<AxiosResponse> {
    return axiosClient.put("users/onboarding", payload);
  },
  contactUs(payload: {
    email: string;
    name: string;
    message: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("feedback", payload);
  },
  forgotPassword(payload: {
    email: string;
    callback: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("users/forgot-password", payload);
  },
  resetPassword(payload: {
    password: string;
    passwordRepeat: string;
    token: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch("users/reset-password", payload);
  },
  updateUserProfile(payload: {
    fullName: string;
    gender: Gender;
    birthDate: string;
    goal: Goal;
    measurementSystem: MeasurementSystem;
  }): Promise<AxiosResponse> {
    return axiosClient.patch("users/me", payload);
  },
  updateUserPassword(payload: {
    password: string;
    newPassword: string;
    passwordRepeat: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch("users/me/password", payload);
  },
  changeUserEmail(payload: { token: string }): Promise<AxiosResponse> {
    return axiosClient.patch("auth/new-email", payload);
  },
  creatingRequestToChangeUsersPassword(payload: {
    newEmail: string;
    password: string;
    callback: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("users/me/email", payload);
  },
  getUserDashboardSettings(): Promise<AxiosResponse> {
    return axiosClient.get("dashboard/settings");
  },
  putUserDashboardSettings(payload: {
    settings: IDashboardSetting[];
  }): Promise<AxiosResponse> {
    return axiosClient.put("dashboard/settings", payload);
  },
  updatePopUpSettings(payload: {
    [key: string]: boolean;
  }): Promise<AxiosResponse> {
    return axiosClient.patch("users/update-pop-up-settings", payload);
  },
  termsOfUse(): Promise<AxiosResponse> {
    return axiosClient.patch("users/sign-terms-of-use");
  },
  updateCompletedSteps(payload: string): Promise<AxiosResponse> {
    return axiosClient.patch("users/complete-step", { stepId: payload });
  },
  deleteAccount(payload: { password: string }): Promise<AxiosResponse> {
    return axiosClient.post("/users/remove-account", payload);
  },
};
