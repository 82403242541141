import { defineStore } from "pinia";
import gameplanApi from "@/services/api/gameplanApi";
import { AxiosResponse } from "axios";
import IOnboardingStartCourse from "@/types/IOnboardingStartCourse";
import { computed, ref } from "vue";
import { Gameplan } from "@/types/Gameplan";

export const useGameplaneStore = defineStore(
  "gameplan",
  () => {
    const userCourse = ref<Partial<Gameplan> | null>(null);

    const getUserCourse = computed(() => userCourse.value);
    const getUserCourseId = computed(() => userCourse.value?.courseId || "");

    function getCourses(
      params = {} as {
        "filter[title]"?: string;
        "filter[type]": string;
        "page[limit]": number;
        "page[skip]": number;
      }
    ): Promise<AxiosResponse> {
      return gameplanApi.getCourses(params);
    }

    function getCoursesRandom(
      params = {} as {
        "filter[limit]": number;
        "filter[challengeId]": string;
      }
    ): Promise<AxiosResponse> {
      return gameplanApi.getCoursesRandom(params);
    }

    function getRecommendedCourses(
      params = {} as {
        "filter[date]": string;
      }
    ): Promise<AxiosResponse> {
      return gameplanApi.getRecommendedCourses(params);
    }

    function getCourse(id: string): Promise<AxiosResponse> {
      return gameplanApi.getCourse(id);
    }

    function getCurrentCourse(): Promise<AxiosResponse> {
      return gameplanApi
        .getCurrentCourse()
        .then((res) => {
          userCourse.value = res.data.data;
          return res;
        })
        .catch((res) => {
          userCourse.value = null;
          return res;
        });
    }

    function cancelCourse(id: string): Promise<AxiosResponse> {
      return gameplanApi.cancelCourse(id).then((res) => {
        userCourse.value = null;
        return res;
      });
    }

    function startCourse(form: IOnboardingStartCourse): Promise<AxiosResponse> {
      return gameplanApi.startCourse(form);
    }

    function uploadFile(
      data: FormData,
      uploadProgressCallback?: (event: ProgressEvent) => void
    ): Promise<AxiosResponse> {
      return gameplanApi.uploadFile(data, uploadProgressCallback);
    }

    return {
      getUserCourse,
      getUserCourseId,
      getCourses,
      getCoursesRandom,
      getRecommendedCourses,
      getCourse,
      getCurrentCourse,
      cancelCourse,
      startCourse,
      uploadFile,
    };
  },
  {
    persist: true,
  }
);
